<template>
  <div class="home">
    <h3 class="common-title">系统设置</h3>
    <div class="clearfix pb20">
      <div class="fr">
        <el-button type="primary" @click="doSave">保存</el-button>
      </div>
    </div>
    <div class="home-left">
      <Empty v-if="!config"></Empty>
      <el-form
        v-else
        :model="configForm"
        :rules="configRules"
        ref="configRuleFormRef"
        label-width="120px"
      >
        <el-form-item label="模型名称" prop="openai_model_name">
          <el-select
            v-model="configForm.openai_model_name"
            placeholder="请选择 model name"
            @change="handleChange"
            disabled
          >
            <el-option
              v-for="item in openaiModelNameOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提示词" prop="openai_prompt">
          <el-input
            type="textarea"
            rows="8"
            v-model="configForm.openai_prompt"
            placeholder="请输入 prompt"
          ></el-input>
        </el-form-item>
        <div v-for="language in config.server_languages">
          <el-form-item :label="`${language.name}提示词`" :prop="`openai_${language.prefix}_prompt`">
            <el-input
              type="textarea"
              rows="8"
              v-model="configForm.multilingual_data[`openai_${language.prefix}_prompt`]"
              :placeholder="`请输入 ${language.name} prompt`"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="停用词" prop="es_stop_words">
          <el-input
            type="textarea"
            rows="10"
            v-model="configForm.es_stop_words"
            placeholder="请输入停用词(用 , 分隔)"
          ></el-input>
        </el-form-item>

      </el-form>

    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { authApi } from '@/api'
import { onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {

    // config form

    const openaiModelNameOptions = ref([
      { value: '01', label: 'gpt-3.5-turbo' },
      { value: '02', label: 'gpt-4' }
    ])

    const loading = ref(false)

    const config = ref(null)
    const configFormRaw = reactive({
      openai_prompt: null,
      openai_model_name: null,
      es_stop_words: null,
      multilingual_data: {},
    })
    const configForm = reactive(JSON.parse(JSON.stringify(configFormRaw)))

    const configRuleFormRef = ref(null)

    const configRules = reactive({
      openai_prompt: [
        { required: true, message: '请输入 prompt', trigger: 'blur' },
      ],
      openai_model_name: [
        { required: true, message: '请选择 model name', trigger: 'blur' },
      ],
    })
    const getOpenaiConfig = () => {
      loading.value = true
      authApi.getConfig().then(res => {
        config.value = res.data
        Object.keys(configFormRaw).forEach(key => {
          configForm[key] = res.data[key]
        })
      }).finally(() => {
        loading.value = false
      })
    }
    const doSave = () => {
      loading.value = true
      configRuleFormRef.value.validate((valid) => {
        if (valid) {
          console.log(configForm)
          authApi.updateConfig(configForm).then(res => {
            console.log(res)
            ElMessage({
              message: '保存成功',
              type: 'success',
            })
          }).finally(() => {
            loading.value = false
          })
        }
      })
    }
    const handleChange = (val) => {
      console.log(val)
      configForm.openai_model_name = openaiModelNameOptions.value.find(item => item.value === val).label
    }

    onMounted(() => {
      getOpenaiConfig()
    })
    return {
      loading,
      configRules,
      configForm,
      configRuleFormRef,
      doSave,
      config,
      openaiModelNameOptions,
      handleChange,
    }
  }
}
</script>
<style scoped>

</style>
